import React, { PureComponent } from "react";
import {
  Popover,
  Button,
  Form,
  OverlayTrigger,
  InputGroup,
} from "react-bootstrap";
import Big from "big.js";

import * as date from "../../../utils/date";
import { getNumberDisplayValueEUR, getNumberDisplayValueVatIncludedEUR } from "../../../utils/format";
import { UserRole } from "../../../utils/config";
import { getNameForOil } from "../../../common/Oils";
import TooltipWrapper from "../../CreateOilRequestPage/sub/TooltipWrapper";
import { calculateMarkupTotal, isMarkup } from "../../../utils/oilRequest";

/**
 * Requester can see all the information only if request is in
 * the following states.
 */
const RequestStates = ["Decision", "Active", "Closed", "Transport"];

type OilRequestOil ={
 oilType: string; 
 id: number; 
 markup: number;
 amount: number;
}

type Props = {
  requesterId: number;
  requestStateCode: string;
  bid: any;
  user: any;
  onOilRequestWinner: (e: any, b: { id: number, markup: number }[]) => void;
  oilRequestMarkup?: string;
  oilRequestOils: OilRequestOil[];
  winnerBidId?: number;
};

type State = {
  markup: Record<string, string>;
  markupError: Record<string, string>;
};

class Bid extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    const m: Record<string, string> = {};
    props.oilRequestOils.forEach((o) => {
      m[o.id] = '';
    });

    this.state = {
      markup: m,
      markupError: {},
    };
  }

  private hidePopover = () => {
    document.body.click();
  };

  private handleOilRequestWinner = (e) => {
    const { onOilRequestWinner } = this.props;
    const { markup } = this.state;
    const errors: Record<string, string> = {};
    const bidId = e.target.getAttribute("data-value");
    for (const [key, value] of Object.entries(markup)) {
      if (value && new Big(value || 0).lt(0)) {
        errors[key] = value;
      }
    }
    if (Object.keys(errors).length !== 0) {
      this.setState({
        markupError: errors,
      });
      return;
    }

    onOilRequestWinner(bidId, Object.entries(markup).map(([key, value]) => ({
      id: parseInt(key, 10),
      markup: parseFloat(value),
    })));
  };

  private handleMarkupChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      markup: {
        ...prevState.markup,
        [name]: value,
      },
      markupError: {
        ...prevState.markupError,
        [name]: undefined,
      },
    }));
  };

  private getBidTooltip = (
    bids: [{ price: number; oilId: number }],
  ): React.ReactNode => {
    const { oilRequestOils } = this.props;
    return oilRequestOils.map((oil) => {
      const bid = bids.find((b) => b.oilId === oil.id);
      return (
        <div key={oil.oilType}>
          {getNameForOil(oil.oilType)}: {bid?.price}
        </div>
      );
    });
  };

  private getTotalMarkup = (): string => {
    const { markup } = this.state;
    const { oilRequestOils } = this.props;
    let totalMarkup = new Big(0)
    oilRequestOils.forEach((o) => {
      if (!(new Big(markup[o.id] || 0).lt(0))) {
        totalMarkup = totalMarkup.plus(new Big(o.amount).times(markup[o.id] || 0));
      }
    });

    return totalMarkup.toFixed();
  }

  private getMarkupTooltip = (): React.ReactNode => {
    const { oilRequestOils } = this.props;
    return (oilRequestOils || [])
      .filter((o) => o.markup)
      .map((o) => {
        return (
          <div key={o.oilType}>
            {getNameForOil(o.oilType)}: {o.markup}
          </div>
        );
    });
  };

  private getMarkupTotalTooltip = (): React.ReactNode => {
    const { oilRequestOils, bid } = this.props;
    return (oilRequestOils || [])
      .map((o) => {
        const oilBid = bid.oilBids.find((ob) => ob.oilId === o.id);
        return (
          <div key={o.oilType}>
            {getNameForOil(o.oilType)}: {new Big(o.markup || 0).plus(oilBid.price).toFixed()}
          </div>
        );
    });
  };

  render() {
    const { bid, user, requesterId, requestStateCode } = this.props;
    const { oilRequestOils } = this.props;

    const REQUESTER =
      RequestStates.includes(requestStateCode) && requesterId === user.id;
    const REQUEST_STATUS_DECISION = requestStateCode === "Decision";

    const confirmPopover = (
      <Popover id="popover-basic" >
        <Popover.Header as="h3">
          Kas olete kindel, et hanke võitjaks kuulutatakse{" "}
          <span className="configrm__emphasize">{bid.companyName}</span>?
        </Popover.Header>
        <Popover.Body>
          {![UserRole.Agent].includes(user.role) && (
            <p>
              Pärast võitja valimist, läheb hange olekusse "Teostamine". Ning
              Teile saadetakse võitja kontaktandmed.
            </p>
          )}
          {[UserRole.Agent].includes(user.role) && (
            <>
              {oilRequestOils.map((oil) => (
                <Form.Group className="mb-2" key={oil.oilType}>
                  <Form.Label className="mb-0 d-block">
                    {getNameForOil(oil.oilType)}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder=""
                      type="number"
                      onChange={this.handleMarkupChange}
                      name={String(oil.id)}
                      value={this.state.markup[oil.id]}
                      isInvalid={false}
                      step={0.0001}
                    />
                    <InputGroup.Text>€</InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      {false}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              ))}
              <Form.Group className="mb-2">
                <Form.Label className="mb-0 d-block">
                  Juurdehindlus
                </Form.Label>
                <Form.Control
                  type="text"
                  value={getNumberDisplayValueEUR(this.getTotalMarkup(), 2)}
                  plaintext
                  readOnly
                  step={0.0001}
                />
              </Form.Group>
              <Form.Group className="mb-2"></Form.Group>
              <Form.Group className="mb-2">
                <Form.Label className="mb-0 d-block">
                  Summa käibemaksuga
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  plaintext
                  readOnly
                  value={
                    getNumberDisplayValueVatIncludedEUR(
                      new Big(bid.price || 0).plus(this.getTotalMarkup() || 0),
                      2,
                    )
                  }
                />
              </Form.Group>
            </>
          )}


        <Button type="button" variant="light" onClick={this.hidePopover}>
          Loobu
        </Button>
        <Button
          type="button"
          variant="warning"
          className="float-end"
          onClick={this.handleOilRequestWinner}
          data-value={bid.id}
        >
          Jah
        </Button>
      </Popover.Body>
      </Popover >
    );

    const companyName = bid.companyName ? `${bid.companyName}` : "Varjatud";
    const price = bid.price ? (
      bid.oilBids ? (
        <TooltipWrapper id={bid.id} text={this.getBidTooltip(bid.oilBids)}>
          <span>{getNumberDisplayValueEUR(new Big(bid.price || 0), 2)}</span>
        </TooltipWrapper>
      ) : (
        <span>{getNumberDisplayValueEUR(new Big(bid.price || 0), 2)}</span>
      )
    ) : (
      "Varjatud"
    );
    const priceWithTax = bid.price
      ? getNumberDisplayValueVatIncludedEUR(new Big(bid.price || 0), 2)
      : "Varjatud";
    const comment = (
      <div>
        {bid.comment ? bid.comment : "–"}
        <div>
          {bid.oilBids?.map((b, index) => {
            const oil = oilRequestOils.find((oro) => oro.id === b.oilId);
            if (b.marking || b.cloudPoint || b.coldFilterPlugPoint) {
              const r = [];
              r.push(`${getNameForOil(oil.oilType)}`);
              if (b.marking) {
                r.push(`Märgistus ${b.marking}`);
              }
              if (b.cloudPoint) {
                r.push(`CP ${b.cloudPoint}`);
              }
              if (b.coldFilterPlugPoint) {
                r.push(`CFPP ${b.coldFilterPlugPoint}`);
              }
              return <div key={index}>{r.join(", ")}</div>;
            }
            return undefined;
          })}
        </div>
      </div>
    );

    const oilRequestMarkupTotal = calculateMarkupTotal({ oils: oilRequestOils} );
    const hasMarkup = isMarkup({ oils: oilRequestOils });
    const markup = (hasMarkup && bid.oilBids?.length) ? 
        <TooltipWrapper id={bid.id} text={this.getMarkupTooltip()}>
          <span>{getNumberDisplayValueEUR(new Big(oilRequestMarkupTotal || 0), 2)}</span>
        </TooltipWrapper>
       : 'Varjatud';
    const markupTotal = (hasMarkup && bid.oilBids?.length) ? 
        <TooltipWrapper id={bid.id} text={this.getMarkupTotalTooltip()}>
          <span>{getNumberDisplayValueEUR(new Big(oilRequestMarkupTotal || 0).plus(new Big(bid.price || 0)), 2)}</span>
        </TooltipWrapper>
       : 'Varjatud';
       const markupTotalWithTax = (hasMarkup && bid.oilBids?.length) ? getNumberDisplayValueVatIncludedEUR(new Big(oilRequestMarkupTotal || 0).plus(new Big(bid.price || 0)), 2) : 'Varjatud';
    const modificationDate = date.getDateTime(bid.modDatetime);
    const tools = ((REQUESTER && REQUEST_STATUS_DECISION) || (user.role === 'Admin' && !this.props.winnerBidId)) && (
      <>
        <OverlayTrigger rootClose trigger="click" placement="top" overlay={confirmPopover}>
          <Button
            type="button"
            className="bids-section__table__btn"
          >
            Vali võitjaks
          </Button>
        </OverlayTrigger>
      </>
    );

    return (
      <>
        <tr className={`bids-section__desktop_tr ${bid.id === this.props.winnerBidId ? 'winner' : ''}`}>
          <td>{companyName}</td>
          <td>{price}</td>
          {!hasMarkup && <td>{priceWithTax}</td>}
          <td style={{ wordBreak: 'break-word', maxWidth: '200px' }}>{comment}</td>
          {hasMarkup && <td>{markup}</td>}
          {hasMarkup && <td>{markupTotal}</td>}
          {hasMarkup && <td>{markupTotalWithTax}</td>}
          <td>{modificationDate}</td>
          <td style={{ verticalAlign: 'middle' }}>{tools}</td>
        </tr>

        <div className={`bids-section__mobile ${bid.id === this.props.winnerBidId ? 'winner' : ''}`}>
          <div style={{ padding: '10px' }}>
            <div className="bids-section__mobile__title">Pakkuja:</div>
            <div className="bids-section__mobile__value">{companyName}</div>

            <div className="bids-section__mobile__title">Pakkumine:</div>
            <div className="bids-section__mobile__value">{price}</div>
            {!hasMarkup && (
              <>
                <div className="bids-section__mobile__title">
                  Pakkumine käibemaksuga:
                </div>
                <div className="bids-section__mobile__value">{priceWithTax}</div>
              </>
            )}
            <div className="bids-section__mobile__title">Kommentaar:</div>
            <div className="bids-section__mobile__value">{comment}</div>
            {hasMarkup && (
              <>
                <div className="bids-section__mobile__title">Juurdehindlus:</div>
                <div className="bids-section__mobile__value">{markup}</div>
              </>
            )}
            {hasMarkup && (
              <>
                <div className="bids-section__mobile__title">Summa:</div>
                <div className="bids-section__mobile__value">{markupTotal}</div>
              </>
            )}
            <div></div>
            {hasMarkup && (
              <>
                <div className="bids-section__mobile__title">Summa käibemaksuga:</div>
                <div className="bids-section__mobile__value">{markupTotalWithTax}</div>
              </>
            )}
            <div className="bids-section__mobile__title">Lisatud:</div>
            <div className="bids-section__mobile__value">{modificationDate}</div>
            <div style={{ textAlign: 'center', paddingBottom: '10px' }}>{tools}</div>
          </div>
        </div>
      </>
    );
  }
}

export default Bid;
